@import "styles/variables";
@import "styles/_mixins.scss";

#cx-accordion-menu {

    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    position: relative;
    background-color: $color-grey-100;

    #previus-content {
        overflow: hidden;
        position: absolute;
    }

    #selected-content {
        width: 100%;
        position: absolute;
    }

    .drawer-item,
    .drawer-item-displayed {
        width: 100%;
        border-radius: 0 !important;
        margin: 0 !important;
        height: 53px !important;
        padding-left: 38px !important;
        padding-right: 18px;
        border-bottom: 1px solid $gray-input-focused;
        background-color: $color-grey-100;

        font-family: Roboto;
        font-weight: 500;
        line-height: $largeplus;
        letter-spacing: 0.04px;
        font-size: $medium;

        @media (width <= $desktop) {
            padding-inline: 40px 21px !important;
        }

        .mdc-deprecated-list-item__ripple::before,
        .mdc-deprecated-list-item__ripple::after {
            opacity: 0 !important;
        }

        &.mdc-ripple-upgraded:not(.separator):not(.mdc-ripple-upgraded--background-focused):active,
        &.mdc-ripple-upgraded:not(.separator):not(.mdc-ripple-upgraded--background-focused):focus {
            background-color: $transparent-blue;
            transition: 0s;
            color: $purple-text;
        }

        .mdc-deprecated-list-item__text {
            width: 100% !important;
        }

        .icon {
            @include square($extralarge);
        }

        .arrow {
            --md-icon-size: #{$medium};
            --cds-icon-color: #{$color-grey-700};
        }

        &.drawer-back-item {
            padding-left: $largeplus !important;
            font-weight: 500;
            color: $color-grey-700;
        }

        &.drawer-list-item {
            color: $color-grey-700;
            font-weight: 500;
        }

        
        &.separator {
            border-bottom: 0 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        &.filtered-background {
            background-color: $color-grey-200;
        }
    }

    mdc-drawer {
        top: 0;
    }

    .mdc-expandable {
        color: $color-grey-600 !important;
        font-size: $medium;
        font-weight: 500;
        line-height: $largeplus;
        letter-spacing: 0.04px;

        .mdc-expandable__dropdown-icon {
            margin-inline: 0;
            @include square($extralarge);
        }

        .drawer-item-expandable .mdc-expandable__header .expandable-item-arrow {
            color:  $color-grey-700;
        }

        .mdc-expandable__header .mdc-expandable__caption {
            flex: auto;
        }

        .mdc-expandable__header {
            width: 100%;
            padding-right: 15px !important;
            padding-left: $bigPad !important;
            justify-content: space-between;
        }
    }
    
    .drawer-subitem {
        gap: 10px;
        
        img {
            height: 28px;
            width: 28px;
        }

        span {
            color: $gray-blue;
        }
    }

    .icon-name-option {
        @include flex($align: center);
        gap: 4px;
    }

    .no-border {
        border-bottom: 0 !important;
    }

    .border {
        border-top: 1px solid $divisor-gray-light;
    }

    #cx-no-results {
        padding: 40px 20px;

        img {
            @include square(32px);
        }
    }

    .arrow {
        --md-icon-size: 16px;
        --cds-icon-color: rgb(107, 107, 107);
    }
}
