@use '@chicksgroup/styles/mixins' as m;
@use '@chicksgroup/styles/variables' as v;

#cx-bank-transfer-form {
    --md-sys-color-on-surface: transparent;

    --cds-menu-padding: v.$default-grid-spacing;
    --cds-menu-overflow: visible;
    --cds-menu-container-border: 1px solid var(--cg-grey-400);
    --cds-menu-scrollbar-thumb-color: var(--cx-secondary-700);

    --cds-option-box-padding-inline: 16px;
    --cds-option-box-padding-block: 8px;
    --cds-option-box-color: var(--cg-grey-100);

    --cds-input-background: var(--cg-grey-250);
    --cds-input-hover-background-color: var(--cg-grey-300);
    --cds-input-focus-background-color: var(--cg-grey-350);

    --cds-text-field-floating-label-color: var(--cg-grey-700);
    --cds-text-field-focus-floating-label-color: var(--cg-grey-700);
    --cds-text-field-leading-icon-size: 24px;
    --cds-text-field-leading-icon-end-margin: 8px;

    --cds-autocomplete-max-height: 400px;
    --cds-autocomplete-color: var(--cg-grey-100);
    --cds-autocomplete-border-color: var(--cg-grey-400);
    --cds-autocomplete-space: 4px;
    --cds-autocomplete-divider-space: 0;
    --cds-autocomplete-shadow-color: transparent;
    --cds-autocomplete-item-label-max-width: 188px;
    --cds-autocomplete-item-label-color: var(--cg-grey-800);
    --cds-autocomplete-item-leading-gap: 8px;
    --cds-autocomplete-item-font-size: v.$regular;
    --cds-autocomplete-item-selected-color: var(--cg-grey-300);
    --cds-autocomplete-item-hover-color: black;
    --cds-autocomplete-item-hover-opacity: 0.04;
    --cds-autocomplete-item-pressed-color: black;
    --cds-autocomplete-item-pressed-opacity: 0.0476;
    --cds-autocomplete-item-disabled-label-color: var(--cg-grey-800);
    --cds-autocomplete-item-disabled-bottom-space: 8px;
    --cds-autocomplete-item-disabled-opacity: 1;
    --cds-autocomplete-item-disabled-font-size: 12px;
    --cds-autocomplete-item-disabled-line-height: 16px;
    --cds-autocomplete-item-disabled-letter-spacing: 0.24px;
    --cds-autocomplete-item-disabled-font-weight: 400;
    --cds-autocomplete-item-disabled-height: 24px;
    --cds-autocomplete-item-label-gap: 8px;
    --cds-autocomplete-width: 266px;

    --md-menu-container-color: var(--cg-grey-100);
    --md-menu-item-pressed-state-layer-opacity: 0;
    --md-filled-text-field-input-text-color: var(--cg-grey-900);
    --md-filled-text-field-hover-input-text-color: var(--cg-grey-900);
    --md-filled-text-field-focus-input-text-color: var(--cg-grey-900);
    --md-filled-text-field-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-hover-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-focus-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-leading-icon-color: var(--cx-secondary-700);
    --md-filled-text-field-hover-leading-icon-color: var(--cx-secondary-700);
    --md-filled-text-field-focus-leading-icon-color: var(--cx-secondary-700);
    --md-filled-text-field-trailing-icon-color: var(--cg-grey-900);
    --md-filled-text-field-container-color: var(--cg-grey-250);
    
    --md-filled-text-field-disabled-container-color: var(--cg-grey-200);
    --md-filled-text-field-disabled-container-opacity: 1;
    --md-filled-text-field-disabled-input-text-color: var(--cg-grey-600)
    --md-filled-text-field-disabled-input-text-opacity: 1;
    --md-filled-text-field-disabled-label-text-color: var(--cg-grey-500);
    --md-filled-text-field-disabled-label-text-opacity: 1;
    --md-filled-text-field-disabled-leading-icon-color: var(--cg-grey-500);
    --md-filled-text-field-disabled-leading-icon-opacity: 1;
    
    --md-filled-text-field-disabled-trailing-icon-color: var(--cg-grey-500);
    --md-filled-text-field-disabled-trailing-icon-opacity: 1;
        
    --cds-text-field-loading-spinner-size: 24px;
    --md-circular-progress-active-indicator-color: var(--cx-secondary-700);

    --cds-primary-color-50: var(--cx-secondary-700);
    --cds-primary-color: var(--cx-secondary-700);

    cds-autocomplete {
        border-radius: v.$thinborder;

        &.no-outline {
            outline: none !important;
        }

        &:hover {
            outline: 1px solid var(--cx-secondary-700);
        }

        &:focus {
            outline: 2px solid var(--cx-secondary-700);
        }
    }

    cds-text-field {
        &:hover {
            --cds-text-field-default-border: 1px solid var(--cx-secondary-700);
        }

        &:focus {
            --cds-text-field-default-border: 2px solid var(--cx-secondary-700);
        }
    }
    
    .card-form {
        padding: 16px;
        outline: none;
        @include m.flex-gap(12px, $direction: column);

        .card-title {
            gap: 8px;
            justify-content: start;
            border-bottom: none;
            max-width: max-content;
            @include m.font(12px, 400, 16px, 0.02em);
        }
    }

    #cx-no-results {
        padding: v.$medium !important;

        span {
            font-size: v.$regular;
        }

        img {
            @include m.square(32px);
        }
    }
}
