@import 'styles/variables';

#cx-blog-search {
    &:hover {
        outline: 1px $purplehover solid;
        border-radius: 12px;
    }

    &:has(.mdc-text-field--focused, .mdc-select--focused) {
        outline: 1px $cx-purple solid;
        border-radius: 12px;
    }

    .cx-form-control:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
    .cx-form-control:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
    .cx-form-control:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
        border-color: $transparent !important;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
        border-color: $transparent !important;
    }

    .cx-form-control {
        border: 0;
        border-radius: 12px;
        background-color: $transparent !important;
    }

    .double-input-extra-field {
        flex-basis: 20%;

        @media (max-width: $phone) {
            width: 55% !important;
        }

        @media (max-width: $tablet) {
            width: 40%;
        }
    }

    .double-input-search {
        flex-basis: 80%;

        @media (max-width: $phone) {
            width: 45% !important;
        }

        @media (max-width: $tablet) {
            width: 60%;
        }
    }

    .joined-input {
        background-color: $white;
        border-radius: 12px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
        display: flex;
    }

    .cx-dropdown {
        width: 100%;
        box-shadow: none;

        .mdc-list-item {
            &:hover {
                background: $lilac-hover;
            }
        }
    }

    input {
        text-overflow: ellipsis;
        padding-right: 25px;
    }

    .close-icon {
        z-index: 2000;
        top: calc(50% - 8px);
        right: 15px;
        color: $black;
        font-size: $medium;
        cursor: pointer;
    }

    .arrow-icon {
        font-size: $largeplus !important;
        color: $cx-purple !important;
    }

    .mdc-select__icon {
        margin-left: 0;
        margin-right: 0;
    }

    .mdc-select__selected-text-container {
        flex-grow: 0;
        margin-right: 10px;
    }

    .mdc-select__anchor {
        gap: 3px;
        justify-content: end;
    }

    mdc-select span:first-child {
        width: fit-content;
        min-width: 49px;
        padding-top: 3px;
    }
}
