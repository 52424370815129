@import 'styles/variables';

#cx-amount-currency-selector {
    --cds-text-field-height: 16px;
    --cds-text-field-width: 100%;
    --md-filled-text-field-trailing-space: 0;
    --md-filled-text-field-leading-space: 0;
    --md-filled-text-field-bottom-space: 0;
    --md-filled-text-field-top-space: 0;
    --md-filled-text-field-caret-color: black;
    --md-filled-text-field-container-color: white;

    .cx-form-control {
        &--outlined {
            height: 43px;
        }

        &__icon--leading {
            margin: 0;
        }

        .mdc-floating-label {
            left: 1px;
            color: $semi-transparent-black;
        }

        border-radius: 0;
    }

    .mdc-text-field__input {
        color: $color-grey-700;
    }

    .input-prefilled {
        color: $color-grey-700;
        letter-spacing: 0.24px;
    }

    .input-empty {
        color: $color-grey-700;
        letter-spacing: 0.24px;
    }

    .amount-currency-container {
        padding: 12px;
        border: 1px solid var(--cg-grey-400);
        border-radius: 6px;
        max-width: 442px;
        height: 76px;
    }

    .cx-form-control:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline {

        &__leading,
        &__notch,
        &__trailing {
            border-color: transparent !important;
        }
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
        border-color: transparent !important;
    }

    .cx-form-control {
        border: 0;
        padding-left: 0 !important;
        background-color: transparent !important;
    }

    .payment-method-selector {

        &:hover,
        &:focus {
            outline: 0 transparent !important;
        }
    }

    .double-input-wallet-address {
        width: 53%;
        display: flex;
        flex-direction: column;
        padding: 8px 0 8px 16px;
        margin-right: 16px;
        height: 52px;
        border: 1px solid transparent;

        .mdc-text-field--filled {
            height: 30px;
            color: $color-grey-1100;
        }

        &.double-input-active-border {
            border: 1px solid $color-grey-1100;
            border-radius: 4px;
            padding: 7px -1px 7px 15px;
        }

        &:hover {
            .mdc-text-field__input {
                color: $color-grey-900;
            }

            .mdc-text-field__input::placeholder {
                color: $color-grey-900;
            }
        }

        &:focus,
        &:focus-within {
            .mdc-text-field__input {
                color: $color-grey-900;
            }

            .mdc-text-field__input::placeholder {
                color: $color-grey-900;
            }
        }
    }

    .icon-with-tooltip {
        color: $color-grey-900;
        display: flex;
        align-items: center;
        font-size: $small;
        position: relative;
        cursor: default;

        @media (width < $desktop) {
            font-size: $regular;
            letter-spacing: 0.035px;
        }

        mdc-icon {
            color: rgb(157, 184, 253);
            font-size: 14px;
        }

        --cds-tooltip-width: max-content;
    }

    cds-tooltip {
        z-index: 1;
    }

    .mdc-text-field__input::placeholder {
        color: $color-grey-500;
    }

    .double-input-extra-field {
        width: 56%;
    }

    .coupon-apply-amount {
        color: $success-green;
        font-size: $small;
        font-weight: 400;
        position: absolute;
        left: 13px;
        bottom: 0;
    }

    @media (width < $desktop) {
        --cds-input-background: transparent;
        --cds-input-focus-background-color: transparent;
        --cds-text-field-height: 60px;
        --cds-text-field-width: 100%;
        --md-filled-text-field-input-text-size: 50px;
        --md-filled-text-field-label-text-size: $medium;
        --md-filled-text-field-label-text-populated-size: $medium;
        --md-filled-text-field-input-text-line-height: 59px;
        --md-filled-text-field-input-text-color: var(--cg-accent-1000);
        --md-filled-text-field-focus-input-text-color: var(--cg-accent-1000);
        --md-filled-text-field-hover-input-text-color: var(--cg-accent-1000);
        --md-filled-text-field-caret-color: var(--cx-secondary-700);
        --md-filled-text-field-focus-caret-color: var(--cx-secondary-700);
        --md-filled-text-field-hover-caret-color: var(--cx-secondary-700);
        --md-filled-text-field-label-text-color: $midgray;
        --md-filled-text-field-hover-label-text-color: $midgray;
        --md-filled-text-field-focus-label-text-color: $midgray;
        --md-filled-text-field-input-text-suffix-color: var(--cg-grey-600);
        --md-filled-text-field-hover-state-layer-opacity: 0;
        --md-filled-text-field-bottom-space: 0px;
        --md-filled-text-field-top-space: 0px;
        --md-filled-text-field-leading-space: 0px;
        --md-filled-text-field-input-text-weight: 500;
        --md-filled-text-field-input-text-placeholder-color: var(--cg-grey-600);
        --cds-text-field-hover-placeholder-color: var(--cg-grey-600);
        --cds-text-field-focus-placeholder-color: var(--cg-grey-600);
    }

    .amount-input-mobile {
        --cds-text-field-input-overflow: ellipsis;
        position: relative;
        margin-left: -2px;

        &.amount-input-mobile-7-digits {
            --md-filled-text-field-input-text-size: 46px;

            .currency-code-suffix {
                font-size: 46px;
                top: 0px;
            }
        }

        &.amount-input-mobile-8-digits {
            --md-filled-text-field-input-text-size: 44px;

            .currency-code-suffix {
                font-size: 44px;
                top: 0px;
            }
        }

        &.amount-input-mobile-9-digits {
            --md-filled-text-field-input-text-size: 40px;

            .currency-code-suffix {
                font-size: 40px;
                top: 0px;
            }
        }

        &.amount-input-mobile-10-digits {
            --md-filled-text-field-input-text-size: 36px;

            .currency-code-suffix {
                font-size: 36px;
                top: 0px;
            }
        }

        cds-text-field {
            @media (width <=$phone) {
                &.mw-100 {
                    max-width: 100% !important;
                }
            }
        }

        .currency-code-suffix {
            width: auto;
            height: 60px;
            line-height: 60px;
            letter-spacing: 0;
            top: -1px;
            text-overflow: ellipsis;
            position: absolute;
            font-size: 50px;
            font-weight: 500;
            color: $color-grey-600;
            pointer-events: none;
            background-color: var(--cg-grey-100);
        }
    }
}
