@import 'styles/variables';

#cx-auth-purchase-flow {
    .checkbox-mobile {
        padding-left: 0.1rem;
    }

    .login-input-field {
        margin-right: 0;

        @media (max-width: $desktop) {
            max-width: unset;
        }
    }

    .input-email {
        max-width: 431px;
        width: 100%;

        @media (max-width: $desktop) {
            max-width: 100%;
            width: 100%;
        }
    }
    
    .circle-icon-email,
    .circle-icon-password {
        position: absolute;
        right: 15px;
        height: 20px;
        width: 20px;
        font-size: $largeplusplus;

        &.error {
            color: $red-color;
        }

        &.check {
            color: $success-green;
        }
    }

    .mini-spinner-icon-email,
    .mini-spinner-icon {
        position: absolute;
        right: 15px;
    }

    .link-click:hover {
        text-decoration: underline !important;
    }

    .subtext {
        font-size: 14px;
        right: 0;
        top: 54px;
        width: fit-content;
        color: $purple-text;
    }

    .tos-copy,
    .tos-copy .link-click {
        @media (max-width: $phone) {
            font-size: $tiny;
        }
    }

    .token-label {
        margin: 11px 0 6px;
        line-height: $medium;

        @media (max-width: $phone) {
            margin-top: 4px;
        }
    }
}
