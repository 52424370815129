#apple-pay-checkout-form {
    .mdc-dialog__content .html-content-wrapper {
        height: 350px;
        width: 100%;
        z-index: 2;
    }

    iframe {
        height: 100%;
        width: 100%;
    }
}
