@use '@material/textfield';
@import 'styles/variables';

#cx-address-input {
    .country-input {
        margin-right: 32px;
    }

    .joined-input {
        border: $transparent 1px solid !important;
        border-radius: 12px;

        &:hover:not(.error-border) {
            border: $purplehover 1px solid !important;
        }

        &.error-border {
            border: $red-hover 1px solid !important;

            &:hover {
                border: $red 1px solid !important;
            }
        }
    }

    .cx-form-control-borderless {
        background-color: $backgroundcontrol !important;
    }

    .circle-icon-address,
    .mini-spinner-icon-address {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
        height: 20px;
        font-size: $largeplusplus;
    }

    .circle-icon-address.error {
        color: $red-color;
    }

    .circle-icon-address.check {
        color: $success-green;
    }

    .verify-icons-container {
        width: 16%;

        @media (width <=$phone) {
            width: 7%;
        }

        @media ($phone <=width <=$desktop) {
            width: 17%;
        }
    }
}
