@use '@material/textfield';
@import 'styles/variables';

#cx-amount-payment-selector {
    .cx-form-select {
        .mdc-floating-label {
            padding-left: 3px;
        }
    }

    .amount-payment-container {
        padding: 10px 14px;
        border: 1px solid var(--cg-grey-400);
        border-radius: 6px;
        height: 76px;
        max-width: 340px;
        min-width: 180px;

        #cx-payment-method-selector {            
            @include textfield.ink-color(var(--cg-grey-900));
        }

        @media (width <=$desktop) {
            padding: 0;
            border: none !important;
            height: 52px;
            outline: none !important;
            max-width: unset;
            width: unset;
        }

        .simplebar-content {
            padding: 20px 0px !important;

            div.text-small.text-darkergray {
                padding: 0px 15px;
            }
        }

        #cx-payment-method-item .payment-method-item {
            .payment-method-item-content > div {
                padding: 7px 15px;
            }
            
            &:hover {
                background: $color-grey-250 !important;
            }

            .payment-method-item-content {
                &:hover {
                    >div {
                        background: $color-grey-250 !important;
                    }
                }
            }
        }
    }

    .cx-form-control:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline {

        &__leading,
        &__notch,
        &__trailing {
            border-color: transparent !important;
        }
    }

    .mdc-notched-outline {

        &__leading,
        &__trailing {
            border-color: transparent !important;
        }
    }

    .cx-form-control {
        border: 0;
        padding-left: 0 !important;
        background-color: transparent !important;
    }

    .payment-method-selector {
        padding: 8px 16px !important;
        background-color: var(--cg-grey-250);

        &:hover {
            outline: 1px solid var(--cx-secondary-600) !important;
            background-color: var(--cg-grey-300);
        }

        &:focus,
        &:focus-within {
            outline: 2px solid var(--cx-secondary-600) !important;
            background-color: var(--cg-grey-350);
        }

        .cx-form-select {
            background-color: inherit !important;
        }
    }

    .double-input-extra-field {
        width: 100%;

        label {
            border-radius: 6px;
        }
    }

    #cx-payment-method-selector {
        .expand-icon {
            margin-left: 4px;
            right: 0;
            position: relative;
        }

        .payment-method-text-field .mdc-text-field__input {
            padding-right: 0;
            padding-left: 8px;
        }

        .expanded-selected.outlined-selector {
            margin-top: 15px !important;

            @media (width < $desktop) {
                margin-top: 2px !important;
            }
        }

        .payment-method-image {
            width: 24px;
            height: 24px;
        }
        
        .payment-method-scrollbar {
            max-height: 375px;
        }
    }
}
