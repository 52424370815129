@import 'styles/variables';

#coming-soon {
    background: $white;
    border-radius: 12px;
    max-width: 500px;
    max-height: 558px;
    box-shadow: 6px 6px 8px 8px rgba(0, 0, 0, 0.08);
    
    @media (max-width: $phone) {
        max-width: 330px;
        max-height: 590px;
    }
}
