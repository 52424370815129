@import 'styles/variables';

#cx-breadcrumbs {
    .breadcrumbs {
        margin-top: 94px;
        width: 1000px;
    
        @media (max-width: $phone) {
            width: 334px;
            margin-left: -20px;
            margin-top: 76px;
        }
    
        @media (max-width: $tablet) {
            margin-left: -5px;
            margin-top: 104px;
        }
    }
    
    ul {
        list-style-type: none;
    }
    
    li {
        float: left;
    
        a, span { 
            color: $softblack;
        }
    
        a {
            @media (max-width: $phone) {
                font-size: $medium;
            }
        }
    }

    .crumb-wrapper a {
        min-width: 43.1px;
    }
    
    .crumb-effect:hover {
        font-weight: 500;
        text-decoration: underline !important;
    }

    .bread-effect:hover {
        margin-right: -1px;
        font-weight: 500;
        text-decoration: underline !important;
    }

    .text-truncate {
        width: 345px;
    }
}
