@use '@material/textfield';
@use '@material/select/_index' as select;
@use 'styles/variables' as var;

#cx-billing-address {
    .circle-icon {
        top: unset;
        right: 15px;
    }

    #billing-country,
    #billing-city,
    #billing-state,
    #postal-code {
        & ~ .circle-icon {
            right: 5px;
        }
    }
    .billing-option {
        color: var.$mediumblack;
        margin-bottom: 7px;
    }

    .input-billing {
        height: 50px;
        background-color: var.$inputgray;
        border-radius: 12px;

        &:hover {
            outline: 1px solid var.$purplehover;
    
            &.input-billing--error {
                outline: 1px solid var.$red-hover;
            }
    
            &.input-billing--success {
                outline: 1px solid var.$success-green-hover;
            }
    
            &.input-billing--warning {
                outline: 1px solid var.$yellow-hover;
            }
        }
    
        &:has(.mdc-text-field--focused) {
            outline: 1px solid var.$purple-text; 
            
            &.input-billing--error {
                outline: 1px solid var.$red;
            }
    
            &.input-billing--success {
                outline: 1px solid var.$success-green;
            }
    
            &.input-billing--warning {
                outline: 1px solid var.$yellow;
            }
        }
    
        .input-password {
            @include textfield.label-color(var.$semi-black);
            @include textfield.ink-color(var.$black);
            border-radius:12px;
            background-color: var.$inputgray;
            font-size: var.$small;
            height: 30px;
            padding-inline: 0 15px;
    
            &.mdc-text-field--disabled { 
                .mdc-text-field__input {
                    color: var.$black;                
                }
    
                .mdc-floating-label {
                color: var.$semi-black;
                }
            }
    
            &:has(.mdc-floating-label--float-above) {
                transform: translateY(5.75px);
                transition: all 0.1s;
            }

            .mdc-floating-label {
                font-size: var.$regular;
                text-overflow: clip;
                overflow: visible;
            }

            &.mdc-text-field--focused {
                @include textfield.ink-color(var.$black);            
            }

            input {
                font-size: var.$regular;
                height: 16px;
                position: absolute;
                bottom: 4px;
                max-width: calc(100% - 15px);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:-internal-autofill-selected {
                    background-color: var.$inputgray !important;
                    color: var.$black !important;
                }
            }
        }

        #billing-autocompleted {
            padding-inline: 15px 40px;

            input {
                max-width: calc(100% - 56px);
            }
        }

        #billing-street {
            padding-left: 15px;
            width: 61.85%;
        }

        #billing-country {
            width: 38.15%;
        }

        #billing-city {
            width: 40.74%;            
        }

        #billing-state {
            width: 34.44%;
            padding-inline: 0 20px;

            input {
                max-width: calc(100% - 20px);
            }
        }

        #postal-code {
            width: 24.82%;
        }

        #billing-street,
        #billing-city {
            padding-left: 15px;

            input {
                max-width: calc(100% - 35px);
            }
        }

        #billing-country ,
        #postal-code,
        #billing-state {    
            .mdc-notched-outline__leading,
            .mdc-notched-outline__trailing {
                display: none;
            }
    
            &.mdc-text-field--outlined .mdc-floating-label {
                left: 0;
            }
        }

        .mdc-text-field--outlined{
            &.mdc-notched-outline--upgraded,
            & .mdc-notched-outline--upgraded {
                .mdc-floating-label--float-above {
                    transform: translateY(-25.75px) !important;
                    font-size: var.$small;
                }
            }
        }
    }

    .mdc-floating-label {
        font-size: var.$small;
    }

    .mdc-list-item__primary-text {
        color: var.$input-placeholder;
        font-size: var.$small;
        letter-spacing: .68px;
    }

    .pac-container {
        bottom: 50px !important;
        min-height: 163px;
        background: var.$white;
        box-shadow: none;
        border-radius: 0px;
        width: 100% !important;
        padding: 12px 0 0;

        .pac-item, 
        .pac-item-query {
            border: none;
            color: rgba(0, 0, 0, 0.30);
        }

        .pac-matched {
            color: var.$black;
            font-weight: 400;
        }
    }

    .second-row {
        margin-top: 10px;
    }

    .was-validated :invalid,
    .is-invalid {
        & ~ .invalid-feedback,
        & ~ .invalid-tooltip {
            display: none;
        }
    }
}
