@import 'styles/variables';

#cx-avatar-dialog {
    .dialogs-wrapper {
        padding: 20px;
        &:hover {
            .close-icon {
                display: block;
            }
        }
    }
    
    .dialog-user-profile {
        width: 200px;
        height: 200px;
        border: 1px solid $purple-text;
        border-radius: 100%;
        object-fit: cover;
    }

    .img-sm {
        width: 76px !important;
        height: 76px !important;
    }

    .img-lg {
        width: 294px !important;
        height: 294px !important;
    }

    .dialog-inner {
        margin-top: 76px;
        width: 70%;
    }
    
    .save { 
        left: 30px;
        top: 25px;
    }

    .drop-zone {
        width: 374px;
        height: 322px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='rgba(55, 56, 75, 0.5)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
        border-radius: 20px;
    }

    .loading-container {
        margin-top: 18px;
        width: 206px;
    }

    .drop-zone-hover {
        padding: 10px;
        background-color: $offlilac
    }

    .return {
        position: absolute;
        top: 20px;
        left: 20px;
        color: $purple-text;
        font-weight: 500;
        font-size: $small;
        cursor: pointer;

        .back-button {
            color: $purple-text;
            font-size: 22px;
        }
    }

    .close-icon {
       position: absolute; 
       right: 20px;
       top: 20px;
       display: none;

       @media (max-width: $desktop) {
            display: block;
       }
    }

    .dialog-action {
        &:hover {
            transform: scale(1.05);
            transition: all 0.2s ease;
        }
        font-size: $medium;
    }

    .brand-iso {
        width: 24px;
        height: 24px;
        shape-rendering: crispEdges;
    }

    .p-btn {
        padding: 0 100px;
        height: 50px;
    }

    .use-btn {
        width: 280px;
        height: 50px;
    }

    .p-manual { 
        width: 461px;
    }

    .vertical-spacer-static {
        margin-top: 40px;
    }

    .chicks-avatar-container {
        margin-top: 34px;
    }

    .chicks-options-spacer {
        margin-top: 20px;
    }

    .avatar-preview {
        margin: 30px 0;
    }

    .home-title {
        margin-top: 30px;
    }

    .avatars-container {
        margin-left: 30px;
        flex-wrap: wrap;

        .avatar-inner-container {
            width: 90px;
            height: 90px;
            margin: 0px 7.5px 10px 7.5px ;
            border: 2px solid transparent;
            border-radius: 100%;

            &:hover {
                .avatar {
                    transform:translateY(-4px);
                }
            }
        }

        .avatar {
            width: auto;
            height: 62px;
            border-right: 1px solid rgba(55, 56, 75, 0.10);
            padding: 0px 21px;
            transition: all .3s ease-in-out;
        }
    }

    .selected {
        border: 2px solid $lilac !important;
        transition: all .2s ease-in-out;
    }

    .cx-linear-progress_success {
        .mdc-linear-progress__buffer-bar {
            background-color: $progressgray;
        }

        .mdc-linear-progress__bar-inner {
            border-color: $cx-purple;
        }
    }

    @media (max-width: $tablet) {
        .avatars-container {
            margin-left: 20px;
        } 

        .chicks-options-spacer {
            margin-top: 15px;
        }

        .dialog-user-profile {
            width: 147px;
            height: 147px;
        }

        .dialog-inner {
            margin-top: 41px;
        }

        .img-lg {
            width: 214px !important;
            height: 214px !important;
        }
    }

    @media (max-width: $phone) {
        .return {
            top: 15px;
        }
        .avatars-container {
            margin-left: 0;

            .avatar-inner-container {
                width: 70px;
                height: 70px;
                margin: 0px 1.5px 5px 1.5px ;
            }

            .avatar {
                border-right: 0;
                padding: 0px;
            }
        } 

        .dialog-inner {
            width: 95%;
        }

        .drop-zone {
            width: 287px;
        }

        .p-btn {
            padding: 0 50px;
        }

        .dialog-action {
            &:hover {
                transform: scale(1.05);
                transition: all 0.2s ease;
            }
            font-size: $regular;
        }

        .dialogs-wrapper {
            padding: 15px;
        }

        .home-title {
            margin-top: 25px;
        }

        .avatar-preview {
            margin: 25px 0;
        }
    }
}

