@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-posts-cards {
    .card-deck {
        display: grid;
        gap: 40px 20px;
        grid-template-columns: repeat(3, 1fr);

        @media (width < v.$desktop) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: unset;
            gap: 20px 16px;
        }

        @media (width < v.$tablet) {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }
    }

    .card {
        min-height: 300px;
        max-width: 294px;
        border-radius: 18px;
        box-shadow: 0px 1px 8px 0px rgba(87, 98, 213, 0.12);
        border: none;
        overflow: hidden;

        @media (width <= v.$desktop) {
            max-width: 263px;
            min-height: 376px;
        }

        @media (width <= v.$phone) {
            max-width: 334px;
        }

        img {
            height: 100px;
            object-fit: cover;
            border-radius: 18px 18px 0 0;
        }

        .no-image {
            height: 100px;
            @include m.flex-center();
            background-color: v.$white;

            span {
                font-size: v.$extraextrahuge;
            }
        }

        .card-body {
            display: flex;
            flex-flow: column nowrap;
            padding: 20px;
            min-height: 290px;

            .card-title {
                font-size: v.$large;
                color: v.$gray-blue;
                width: 100%;

                @media (width <= v.$tablet) {
                    font-size: v.$regular;
                    font-weight: 900;
                }

                @media (width <= v.$phone) {
                    font-size: v.$large;
                }
            }

            h5 {
                width: fit-content;
                max-width: 254px;
                margin-bottom: 5px;
                line-height: 1;

                @media (width <= v.$desktop) {
                    max-width: 223px;
                }

                @media (width <= v.$tablet) {
                    margin-bottom: 14px;
                }

                @media (width <= v.$phone) {
                    margin-bottom: 5px;
                    max-width: 294px;
                }

                a {
                    margin-bottom: 0;
                    @include m.ul-hover(true);
                }
            }

            .card-text {
                margin-bottom: 18px;
                font-size: v.$regular;
                color: v.$gray-blue;
                letter-spacing: 0.1px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: wrap;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                min-height: 96px;
                font-weight: 400;
                opacity: .75;

                @media (width <= v.$tablet) {
                    margin-bottom: 14px;
                }

                @media (width <= v.$phone) {
                    margin-bottom: 18px;
                    height: auto;
                }

                p {
                    margin: 0;
                }
            }

            h2 {
                font-size: v.$regular;
            }

            .ap-dynamic-content p {
                margin-bottom: 0;
            }

            .card-footer {
                background: transparent;
                border-color: v.$divisor-gray;
                padding: 0;
                margin-top: 18px;
                color: v.$darkergray;

                @media (width <= v.$tablet) {
                    margin-top: 14px;
                }

                @media (width <= v.$phone) {
                    margin-top: 18px;
                }

                p,
                a {
                    margin-top: 18px;
                    margin-bottom: 0;

                    @media (width <= v.$tablet) {
                        margin-top: 14px;
                    }

                    @media (width <= v.$phone) {
                        margin-top: 18px;
                    }
                }

                a {
                    color: v.$purple-text;
                    font-size: v.$regular;
                    @include m.ul-hover(true);
                }
            }

            .card-author {
                color: v.$darkergray;
                @include m.ul-hover(true);
                
                &, a {
                    @include m.flex();
                    width: fit-content;
                    margin-right: auto;
                }
            }

            .footer-blog {
                @media (width <= v.$tablet) {
                    min-height: 40px;
                }

                a p {
                    margin: 0;
                    color: v.$gray-blue;
                }
            }

            .tag-container {
                min-height: v.$morehuge;

                @media (width <= v.$tablet) {
                    margin-bottom: v.$regular;
                }
            }

            .tag-label {
                font-weight: 300;
                font-size: v.$regular;
                margin-bottom: 10px;
                max-width: max-content;
                margin-right: 6px;

                @include m.default-anchor();

                @media (width <= v.$phone) {
                    margin-bottom: 10px;
                    font-size: v.$small;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
