@import 'styles/variables';

#cx-authenticate-form {
    $labelPosition: -82px;
    border-radius: 4px;
    height: 100%;

    button {
        padding: unset;
        border: unset;
        background-color: transparent;
    }

    .separator-text {
        color: $black;
    }

    .progress-line {
        height: 3px;
        left: 2px;
        top: 62px;
    }

    .title {
        font-size: 40px;
        margin-bottom: 40px;
        letter-spacing: 1.28px;
        line-height: 47px;
        
        @media (max-width: 1280px) {
            font-size: 35px;
        }
        
        @media (max-width: $phone) {
            font-size: 30px;
            margin-bottom: 38px;
        }
    }

    .subtitle {
        padding-top: 53px;
    }

    .heading {
        font-weight: 500;
        margin-bottom: 11px;
    }

    .text-auth {
        color: $black;
    }

    .text-forgot {
        color: $black;
    }

    .link-auth {
        color: $cx-purple !important;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .input-password input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $inputgray inset;
        -webkit-box-shadow: 0 0 0px 1000px $inputgray inset;
    }

    .text-underline {
        font-weight: 400;

        &:hover {
            text-decoration: underline !important;
        }
    }

    .mdc-text-field__input {
        padding-right: 30px;
    }

    .link-click {
        color: $green;
    }

    .link-click:hover {
        color: rgba(57, 226, 157, 0.75);
        text-decoration: underline;
    }

    .circle-icon {
        height: 20px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 11px;
        top: 12px;
    }

    .sign-in-icons {
        width: 30px;
        height: 30px;
    }

    .websites-container {
        margin: 40px 0 10px;
    
        @media (max-width: $phone) {
            margin: 20px 0 10px 0;
        }
    }

    .acckings-website {
        margin-inline: $regularPad;
    }

    .chicksx-website {
        margin-left: $regularPad;
    }

    .gamertotal-website {
        margin-right: $regularPad;
    }

    cx-touch-target {
        &:nth-child(1) .auth-opt img::before,
        &:nth-child(2) .auth-opt img::before {
            @include square(0);
            visibility: hidden;
            opacity: 0;
        }

        &:nth-child(1) .auth-opt img::before {
            content: url("/static/icons/google-hover.svg");
        }
    
        &:nth-child(2) .auth-opt img::before {
            content: url("/static/icons/apple-hover.svg");
        }
    
        &:nth-child(3) .auth-opt img::before {
            content: url("/static/icons/discord-hover.svg");
        }

        &:nth-child(2) .auth-opt {
            margin-inline: $regularPad;
        }     
    
        &:nth-child(1) .auth-opt:hover img {
            content: url("/static/icons/google-hover.svg");
        }

        &:nth-child(2) .auth-opt:hover img {
            content: url("/static/icons/apple-hover.svg");
        }

        &:nth-child(3) .auth-opt:hover img {
            content: url("/static/icons/discord-hover.svg");
        }
    }

    .social-media-sign-up-icons {
        margin-top: 30px;
    }


    .chicksgold-website:hover img {
        content: url("/static/icons/chicksgold-hover.svg");
    }

    .chicksx-website:hover img {
        content: url("/static/icons/chicksx-hover.svg");
    }

    .acckings-website:hover img {
        content: url("/static/icons/acckings-hover.svg");
    }

    .gamertotal-website:hover img {
        content: url("/static/icons/gamertotal-hover.svg");
    }

    .divicasales-website:hover img {
        content: url("/static/icons/divicasales-hover.svg");
    }

    .chicks-group-label,
    .or-label,
    .all-accounts-label {
        font-size: 12px !important;
    }

    .all-accounts-label {
        margin-top: 10px;
    }

    .chicks-group-label:before,
    .chicks-group-label:after,
    .or-label:before,
    .or-label:after {
        content: "";
        background-color: $black;
        position: absolute;
        width: 72px;
        height: 1px;
        top: 8px;

        @media (max-width: 1280px) {
            background-color: $white;
        }

    }

    .chicks-group-label:before,
    .or-label:before {
        left: $labelPosition;
    }

    .chicks-group-label:after,
    .or-label:after {
        right: $labelPosition;
    }

    #login-container {
        & #username-input {
            margin-bottom: 20px;
        }

        & #password-input {
            margin-bottom: 20px;
        }

        & #token-input {
            margin-bottom: 20px;
        }

        .spin-loader {
            margin: 20px 0 15px 0;
        }
    }

    #register-container {
        & #register-username-input {
            margin-bottom: 20px;
        }

        & #register-password-input {
            margin-bottom: 20px;
        }
    }

    #reset-container {
        .confirm-pwd-container {
            margin-top: 20px;
        }
    }

    .options-container {
        margin: 40px 0;
    
        @media (max-width: $phone) {
            margin: 20px 0;
        }
    }

    .text-small {
        font-size: $small;
        letter-spacing: 0.56px;
    }

    .register-paragraph {
         width: 27.5vmin;
    
        @media (width <=1279px) {
            width: 276px;
        }
    
        @media (width <=$phone) {
            width: 100%;
        }
    }

    @media (max-width: 1280px) {
        background-color: transparent;

        .text-auth {
            color: $white;
        }

        .text-forgot {
            color: $white;
        }

        .link-auth {
            color: $green !important;
        }

        .separator-text {
            color: $white;
        }

        .all-accounts-label {
            color: $white;
        }
    }

    @media (max-width: $desktop) {

        .text-small {
            font-size: $small !important;
        }

        .text-regular {
            font-size: $small !important;
        }
    }

    @media (max-width: $phone) {
        .subtitle {
            padding-top: 4px;
        }

        .register-paragraph {
            padding-left: 50px;
            padding-right: 50px;
        }

        #register-container {
            & #register-username-input {
                margin-bottom: 15px;
            }

            & #register-password-input {
                margin-bottom: 15px
            }
        }

        #login-container {
            & #username-input {
                margin-bottom: 15px;
            }

            & #password-input {
                margin-bottom: 15px;
            }

            & #token-input {
                margin-bottom: 15px;
            }
        }
    }

    .text-regular {
        line-height: 16px;
        letter-spacing: 0.133px;
    }

    .margin-question-have-account {
        margin: 10px 0 30px 0 !important;
    }

    .mt-10px {
        margin-top: 10px !important;
    }
}
