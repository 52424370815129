@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-sticky-post {
    width: 100%;
    height: auto;

    .blog-image {
        margin-bottom: 40px;
        width: 100%;
        max-height: 300px;

        @media (max-width: v.$desktop) {
            max-height: 250px;
            margin-bottom: 20px;
        }

        @media (width <  v.$tablet) {
            max-height: 150px;
        }
    }

    .content-box {
        width: 100%;

        a {
            font-size: v.$extrahugetitle;
            color: v.$softblack;
            text-decoration: none;
        }
    }

    .content {
        font-size: v.$medium;
        color: v.$mediumblack;
        line-height: 32px;
        max-height: 426px;
        margin-top: v.$small;

        p {
            margin-bottom: 0;
        }

        @media (max-width: v.$tablet) {
            font-weight: 400;
            font-size: v.$regular;
            margin-top: v.$medium;
        }

        @media (max-width: v.$phone) {
            margin-left: 195px;
        }
    }

    .read-more {
        width: 146px;
        height: 45px;
        border-radius: 6px;
        transition: 0.4s;
        background: v.$cx-purple-gradient;
        border-color: transparent;
        color: white;
        font-size: 16px;

        &:hover {
            background: v.$cx-purple-gradient-hover;
        }
    }

    .blog-title {
        line-height: 40px;

        @media (max-width: v.$desktop) {
            font-size: v.$extralarge !important;
            font-weight: 900;
            line-height: 24px;
        }

        @media (max-width: v.$phone) {
            margin-left: 195px;
        }
    }

    .content-sticky {
        @media (max-width: v.$phone) {
            margin-left: 0;
        }
    }

    .inside-content {
        margin-block: v.$small;

        @media (width <= v.$tablet) {
            margin-top: v.$medium;
        }
    }

    .label-content {
        font-size: v.$regular;
        line-height: v.$regular;

        @include m.default-anchor();

        & span {
            float: left;
            font-weight: 300;
        }

        & a {
            float: right;
            font-weight: 400;

            & p {
                font-size: v.$regular;
            }
        }
    }

    .author-data {
        text-align: right;
    }

    .tag-label {
        float: left;
        margin-bottom: 2px;
        margin-right: 6px;

        &:hover {
            text-decoration: underline !important;
        }

        &:last-child {
            margin-right: 0px;
        }

        @media (width < v.$tablet) {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }
}
